import { get, post, remove } from "../_methods/download";

export const schoolService = {
  createSchool,
  updateSchool,
  getSchools,
  getSchoolsGeo,
  getSchool,
  removeSchool,
  mergeSchools
};

async function createSchool(model) {
  return await post(`/api/schools/create`, true, model);
}

async function updateSchool(id, model) {
  return await post(`/api/schools/update/${id}`, true, model);
}

async function removeSchool(id) {
  return await remove(`/api/schools/${id}`, true);
}

async function getSchools(page, pageSize, filter) {
  const schoolCode = filter && filter.schoolCode ? filter.schoolCode : "";
  const school = filter && filter.school ? `&schoolOld=${filter.school}` : "";
  const regionCode = filter && filter.regionCode ? filter.regionCode : "";
  const districtCode = filter && filter.districtCode ? filter.districtCode : "";
  const cityCode = filter && filter.cityCode ? filter.cityCode : "";
  const noNew = filter && filter.codeUpdatedByCoordinator != undefined ? `&codeUpdatedByCoordinator=${filter.codeUpdatedByCoordinator}` : '';
  const isCoordinator = filter && filter.noNew != undefined ? `&noNew=${filter.noNew}` : '';
  const asc = filter && filter.asc != undefined ? `&asc=${filter.asc}` : '';
  return await get(`/api/schools?page=${page}&pageSize=${pageSize}&isNewHierarchy=false&sc=${schoolCode}&rc=${regionCode}&dc=${districtCode}&cc=${cityCode}${noNew}${isCoordinator}${school}${asc}`, true);
}

async function getSchoolsGeo(page, pageSize, filter) {
  const schoolCode = filter && filter.schoolCode ? filter.schoolCode : "";
  const regionCode = filter && filter.regionCode ? filter.regionCode : "";
  const districtCode = filter && filter.districtCode ? filter.districtCode : "";
  const cityCode = filter && filter.cityCode ? filter.cityCode : "";
  const school = filter && filter.school ? `&schoolNew=${filter.school}` : "";
  const noNew = filter && filter.codeUpdatedByCoordinator != undefined ? `&codeUpdatedByCoordinator=${filter.codeUpdatedByCoordinator}` : '';
  const isCoordinator = filter && filter.noNew != undefined ? `&noNew=${filter.noNew}` : '';
  const asc = filter && filter.asc != undefined ? `&asc=${filter.asc}` : '';
  return await get(`api/schools?page=${page}&pageSize=${pageSize}&isNewHierarchy=true&gsc=${schoolCode}&grc=${regionCode}&gdc=${districtCode}&gcc=${districtCode}${noNew}${isCoordinator}${school}${asc}`, true);
}

async function getSchool(id) {
  return await get(`api/schools/${id}`, true);
}

async function mergeSchools(model) {
  return await post(`api/schools/replace`, true, JSON.stringify(model));
}


export default schoolService;
