import { Fetch } from "../_helpers/handle-response";
import { loggingService } from "../_services";
import { startsWith } from "lodash";

export const get = async (url, isAuth, body, allowAnonymous = false) => {
  const requestOptions = {
    method: 'GET',
    body: body,
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
  };
  try {
    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous);
    if (response) {
      var result = await response.json();
      if (response.ok)
        return result;
      else {
        return { error: result.error, url: response.url };
      }
    } else {
      return null;
    }
  } catch (err) {
    return { error: `Url: ${url}. Error: ${err && err.message ? err.message : err}` }
  }
};

export const remove = async (url, isAuth, allowAnonymous = false) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
  };
  try {
    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous);
    if (response) {
      var result = await response.json();
      if (response.ok)
        return result;
      else {
        return { error: result.error, url: response.url };
      }
    } else {
      return null;
    }
  } catch (err) {
    return { error: `Url: ${url}. Error: ${err && err.message ? err.message : err}` }
  }
};

export const post = async(url, isAuth, body, allowAnonymous = false, redirectUrl = null, isPdf = false) => {
    const requestOptions = {
        method: 'POST',
        headers: body === undefined ? {} : isPdf ? ({ 'Accept':'application/json','Content-Type': 'application/json' }) : { 'Accept':'application/json','Content-Type': 'application/json' },
        body: body
    };

    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous).then(
      async function (response) {
        if (response && response.headers && response.headers.get("content-type") === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
              response.blob()
                .then((blob) => {
                  
                  // 2. Create blob link to download
                  const url = window.URL.createObjectURL(new Blob([blob],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `export.xlsx`);
                  // 3. Append to html page
                  document.body.appendChild(link);
                  // 4. Force download
                  link.click();
                  // 5. Clean up and remove the link
                  link.parentNode.removeChild(link);
                })
                .catch((error) => {
                  return { error: `Url: ${url}. Error: ${error && error.message ? error.message : error}` }
                });

              return null;
          }
            if (response && response.headers && response.headers.get("content-type") === "application/pdf") {
              return await response.blob().then(async (blob) => {
                let pdf = await window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf;' }));
                return pdf;
              }).catch((error) => {
                return { error: `Url: ${url}. Error: ${error && error.message ? error.message : error}` }
              });
            }
            if (response && response.code !== 422) {
              let result = await response.json();
              return result;
            }

            if (response && response.status !== 200 && response.status !== 201) {
                  alert('Looks like there was a problem. Status Code: ' +
                      response.status);
                  return;
            }

            if (response && response.ok && redirectUrl != null) {
                window.location.href = redirectUrl;
            }

            if (response) {
              var result = await response.json();
              if (response.ok)
                return result;
              else {
                return { error: result.error, url: response.url };
              }
            }
      }
    ).then(response => {

        if (response && response.data === 400) {
            alert('Error : ' + response.error.message);
        }

        if (response && response.exception) {
            return { error: { errors: [{ message: response.message }]}}
        }

        return response;

    }).catch(function (err) {
      return { error: `Url: ${url}. Error: ${err && err.message ? err.message : err}` }
    });

    return response;
}

export const put = async (url, isAuth, body, allowAnonymous = false, redirectUrl = null) => {
  const requestOptions = {
    method: 'PUT',
    headers: body === undefined ? {} : { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    body: body
  };

  const response = await Fetch(url, requestOptions, isAuth, allowAnonymous).then(

    async function (response) {
      if (response && response.headers && response.headers.get("content-type") === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        response.blob()
          .then((blob) => {

            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob],
              { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export.xlsx`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            return { error: `Url: ${url}. Error: ${error && error.message ? error.message : error}` }
          });

        return null;
      }

      if (response && response.code !== 422) {
        let result = await response.json();
        return result;
      }

      if (response && response.status !== 200 && response.status !== 201) {
        alert('Looks like there was a problem. Status Code: ' +
          response.status);
        return;
      }

      if (response && response.ok && redirectUrl != null) {
        window.location.href = redirectUrl;
      }

      if (response) {
        let result = await response.json();
        if (response.ok)
          return result;
        else {
          return { error: result.error, url: response.url };
        }
      }
    }
  ).then(response => {

    if (response && response.data === 400 && response.error) {
      alert('Error : ' + response.error.message);
    }

    if (response && response.exception) {
      return { error: { errors: [{ message: response.message }] } }
    }

    return response;

  }).catch(function (err) {
    return { error: `Url: ${url}. Error: ${err && err.message ? err.message : err}` }
  });

  return response;
}

export const postFile = async(url, isAuth, body, allowAnonymous = false, redirectUrl = null) => {
    const requestOptions = {
        method: 'POST',
        body: body
    };

    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous).then(
        function (response) {
            if (response.status !== 200) {
                alert('Looks like there was a problem. Status Code: ' +
                    response.status);
                return;
            }
            if (response.ok && redirectUrl != null) 
                window.location.href = redirectUrl;

            return response.json();
        }
    ).then(response => {
        if (response.data === 400) 
            alert('Error : ' + response.error.message);

        return response;

    }).catch(function (err) {
      return { error: `Url: ${url}. Error: ${err && err.message ? err.message : err}` }
    });

    return response;
}

export const sendLog = async (error) => {
  const logInfo = { message: error };
  loggingService.sendLog(JSON.stringify(logInfo));
}

export const processApiCall = async (callApi, setData, logicError, error) => {
  try {
    let response = await callApi;
    if (!response) {
      return;
    } 
    if (response.error) {
      let userId = localStorage.getItem("user");
      userId = userId ? JSON.parse(userId) : null;
      let text = response.error.message ? response.error.message : response.error;
      if (response.error.errors) {
        Array.forEach(response.error.errors, err => {
          text = text.concat(`\n${err.field}: ${err.message}`);
        });
      }
      const code = response.error.code ? response.error.code : 401;
      const additionalData = response.error.additionalData ? response.error.additionalData : null;
      const description = response.error.description ? response.error.description : null;
      logicError(text, code, additionalData, description);
      if (!text.includes("token_expired")) {
        if (startsWith(text, "Url"))
          await sendLog(response.error + `/ UserId: ${userId}`);
        else {
          let auth = localStorage.getItem("currentAuth");
          auth = auth ? JSON.parse(auth) : null;
          let hash = window.location.hash;
          let tokenRequest = null;
          if (hash.includes('/student/request/group') && hash.includes('?token='))
            tokenRequest = window.location.hash.match(`token=(.*)`)[1];
          await sendLog("Url: " + response.url + ". Message: " + text + `/ UserId: ${userId}. AuthToken: ${auth?.access_token}. RequestToken: ${tokenRequest}`);
        }
      }
    } else
      setData(response.data, response.dataTotal, response.additionalData);
  }
  catch (err) {
    let userId = localStorage.getItem("user");
    userId = userId ? JSON.parse(userId) : null;
    let errorMessage = err.message ? err.message : err;
    if (errorMessage.includes("is not a function")) {
      errorMessage = "Ошибка сервера";
      error(errorMessage);
    } else {
      error(errorMessage);
    }
    
    errorMessage = errorMessage + `/ UserId: ${userId}; Url: ${window.location.href}`;
    sendLog(errorMessage);
  }
}
