// ReSharper disable InconsistentNaming
import React, { Component, useRef } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { auth, userService } from "./_services";
import { GlobalContext } from "./_services/context";
import { createBrowserHistory } from 'history';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// ReSharper disable once UnknownCssClass
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className='sk-spinner spinner-r1'></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const PlayerLayout = React.lazy(() => import('./containers/PlayerLayout'));
const Login = React.lazy(() => import('./views/Pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/Pages/RestorePassword/ForgotPassword'));
const RestorePassword = React.lazy(() => import('./views/Pages/RestorePassword/RestorePassword'));
const ChangePassword = React.lazy(() => import('./views/Pages/RestorePassword/ChangePassword'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const PreRegister = React.lazy(() => import('./views/Pages/Register/PreRegister'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const ExternalParticipation = React.lazy(() => import('./views/Pages/ExternalParticipation/ExternalParticipation'));
const ExternalProfile = React.lazy(() => import("./views/Pages/ExternalParticipation/ExternalProfile"));
const SuccessPayment = React.lazy(() => import("./views/Pages/ExternalParticipation/SuccessPayment"));
const SuccessPaymentPublic = React.lazy(() => import("./views/Pages/ExternalParticipation/SuccessPaymentPublic"));
const PublicVariantPreview = React.lazy(() => import("./views/Pages/Preview/PreviewTasks"));
const YandexKassaPublic = React.lazy(() => import("./_components/YandexKassaPublic"));
const TinkoffKassaPublic = React.lazy(() => import("./_components/TinkoffKassaPublic"));
const ExternalPaymentMultiple = React.lazy(() => import('./views/Pages/ExternalParticipation/Payment/Payment'));
const TeacherReport = React.lazy(() => import('./views/Teacher/Results/Parts/Report'));

const history = createBrowserHistory();


class App extends Component {
  // ReSharper restore InconsistentNaming

  constructor(props) {
    super(props);

    this.tawkMessengerRef = React.createRef(null);

    this.showLoader = () => {
      this.setState({ loader: { isOpen: true } });
    };

    this.hideLoader = () => {
      this.setState({ loader: { isOpen: false } });
    };

    this.setError = (error) => {
      this.setState({ error: error });
    };

    this.clearError = () => {
      this.setState({ error: null });
    };

    this.showApplyRegisterRequestModal = (requestId, isReject = false, isComment = false) => {
      this.setState({ applyRegisterRequest: { isOpen: true, requestId: requestId, isReject: isReject, isComment: isComment } });
    };

    this.hideApplyRegisterRequestModal = () => {
      this.setState({ applyRegisterRequest: { isOpen: false, requestId: null, isReject: false } });
    };

    this.showCreateBlockVariant = (block) => {
      this.setState({ blockVariant: { isOpen: true, block: block, isInit: true } });
    };

    this.hideCreateBlockVariant = () => {
      this.setState({ blockVariant: { isOpen: false, block: null, isInit: false } });
    };

    this.showContestantCreate = (contestant, formats) => {
      this.setState({ createContestant: { isOpen: true, contestant: contestant, formats: formats } });
    };

    this.hideContestantCreate = () => {
      this.setState({ createContestant: { isOpen: false, contestant: null, formats: [] } });
    };

    this.setLogicError = (error) => {
      this.setState({ logicError: error });
    }

    this.showModalShareLinkGroupRequest = () => {
      this.setState({ shareLinkGroupRequest: { isOpen: true } });
    };

    this.hideModalShareLinkGroupRequest = () => {
      this.setState({ shareLinkGroupRequest: { isOpen: false } });
    };

    this.showModalError = (text, returnUrl) => {
      this.setState({ errorModal: { isOpen: true, text: text, returnUrl: returnUrl } });
    };

    this.hideModalError = () => {
      this.setState({ errorModal: { isOpen: false, text: null, returnUrl: null } });
    };

    this.showSetTimeRequest = (requestId, times) => {
      this.setState({ startTimeRequest: { isOpen: true, requestId: requestId, times: times } });
    };

    this.hideSetTimeRequest = () => {
      this.setState({ startTimeRequest: { isOpen: false, requestId: null, times: null } });
    };

    this.showResultErrors = (errors) => {
      this.setState({ resultErrors: { isOpen: true, errors: errors } });
    };

    this.hideResultErrors = () => {
      this.setState({ resultErrors: { isOpen: false, errors: [] } });
    };

    this.showAwardSettings = (activeSettingsRegion, activeSettingsDistrict, activeSettingsSchool) => {
      this.setState({ awardSettings: { isOpen: true, settings: { activeSettingsRegion, activeSettingsDistrict, activeSettingsSchool } } });
    };

    this.hideAwardSettings = () => {
      this.setState({ awardSettings: { isOpen: false, settings: null } });
    };

    this.showSelectContestants = (isExistsRequest = false) => {
      this.setState({ selectContestants: { isOpen: true, isExistsRequest } });
    };

    this.hideSelectContestants = () => {
      this.setState({ selectContestants: { isOpen: false, isExistsRequest: false } });
    };

    this.showSetSchools = (id, selectedSchools) => {
      this.setState({ setSchools: { isOpen: true, selectedSchools: selectedSchools ? selectedSchools : this.state.setSchools.selectedSchools, userId: id } });
    };

    this.hideSetSchools = () => {
      this.setState({ setSchools: { isOpen: false, selectedSchools: [], userId: null } });
    };

    this.showCoordinatorSetSchools = (id, selectedSchools) => {
      this.setState({
        setCoordinatorSchools: {
          isOpen: true, selectedSchools: selectedSchools
            ? selectedSchools
            : this.state.setSchools.selectedSchools || this.state.setCoordinatorSchools.selectedSchools
          , userId: id
        },
      });
    };

    this.hideCoordinatorSetSchools = (saveTemp = false) => {
      this.setState(
        prevState => ({
          setCoordinatorSchools: {
            isOpen: false,
            userId: null,
            selectedSchools: saveTemp ? prevState.setCoordinatorSchools.selectedSchools : []
          },
          setSchools: { isOpen: false, userId: null }
        }));
    };

    this.showCoordinatorEditSchool = (selectedSchoolId) => {
      this.setState({ coordinatorEditSchool: { isOpen: true, selectedSchoolId: selectedSchoolId } });
    };

    this.hideCoordinatorEditSchool = () => {
      this.setState({ coordinatorEditSchool: { isOpen: false, selectedSchoolId: null, userId: null } });
    };

    this.showCoordinatorEditCity = (regionId, districtId, selectedCityId) => {
      this.setState({ coordinatorEditCity: { isOpen: true, selectedCityId: selectedCityId, regionId, districtId } });
    };

    this.hideCoordinatorEditCity = () => {
      this.setState({ coordinatorEditCity: { isOpen: false, selectedCityId: null, userId: null } });
    };

    this.showCoordinatorEditDistrict = (regionId, selectedDistrictId) => {
      this.setState({ coordinatorEditDistrict: { isOpen: true, selectedDistrictId: selectedDistrictId, regionId } });
    };

    this.hideCoordinatorEditDistrict = () => {
      this.setState({ coordinatorEditDistrict: { isOpen: false, selectedDistrictId: null, userId: null } });
    };

    this.updateCoordinatorSetSchools = async (schools) => {
      await this.setState({ setCoordinatorSchools: { isOpen: true, selectedSchools: schools } });
    }

    this.updatePlayerData = async (contestant, tasks, task, endTime, requestId, contestantId, testNotLimited, taskVariants) => {
      await this.setState({ playerData: { contestant, tasks, task, endTime, requestId, contestantId, testNotLimited, taskVariants, selectedTask: this.state.playerData.selectedTask } });
    };

    this.setContestants = (ids) => {
      this.setState({ contestants: ids });
    };

    this.clearContestants = () => {
      this.setState({ contestants: [] });
    };

    this.changeNeedLogin = (needLogin) => {
      this.setState({ needLogin });
    };

    this.selectPlayerTask = async (taskId) => {
      await this.setState({
        playerData: {
          selectedTask: taskId,
          contestant: this.state.playerData.contestant,
          tasks: this.state.playerData.tasks,
          task: this.state.playerData.task,
          endTime: this.state.playerData.endTime,
          requestId: this.state.requestId,
          testNotLimited: this.state.playerData.testNotLimited,
          taskVariants: this.state.playerData.taskVariants,
        }
      });
    };

    this.selectSchool = (schoolId, isOpen = true) => {
      let selectedSchools = this.state.setSchools.selectedSchools;
      if (!selectedSchools.includes(schoolId)) {
        selectedSchools.push(schoolId);
        this.setState({ setSchools: { isOpen: isOpen, selectedSchools, userId: this.state.setSchools.userId } });
      } else {
        selectedSchools = selectedSchools.filter(x => x !== schoolId);
        this.setState({ setSchools: { isOpen: isOpen, selectedSchools, userId: this.state.setSchools.userId } });
      }
    };

    this.selectCoordinatorSchool = (schoolId, isOpen = true) => {
      let selectedSchools = this.state.setSchools.selectedSchools;
      if (!selectedSchools.includes(schoolId)) {
        selectedSchools.push(schoolId);
        this.setState({ setSchools: { isOpen: isOpen, selectedSchools, userId: this.state.setSchools.userId } });
      } else {
        this.setState({ setSchools: { isOpen: isOpen, selectedSchools, userId: this.state.setSchools.userId } });
      }
    };

    this.showSetNotificationUsers = () => {
      this.setState({ notificationUsers: { isOpen: true, selectedUsers: [] } });
    };

    this.hideSetNotificationUsers = () => {
      this.setState({ notificationUsers: { isOpen: false, selectedUsers: [] } });
    };

    this.saveNewTeacherVariant = async (variant) => {
      await this.setState({ newTeacherVariant: variant });
    };

    this.saveNewTasksForTeacherVariant = async (taskIds, removedTasks = []) => {
      await this.setState({ newTasksForTeacherVariant: taskIds, removedTasksForTeacherVariant: removedTasks });
    };

    this.state = {
      currentUser: null,
      loader: { isOpen: false },
      error: null,
      needLogin: false,
      applyRegisterRequest: { isOpen: false, requestId: null, isReject: false, isComment: false },
      blockVariant: { isOpen: false, block: null, isInit: false },
      createContestant: { isOpen: false, contestant: null, formats: [] },
      shareLinkGroupRequest: { isOpen: false },
      playerData: { contestant: null, tasks: [], task: null, selectedTask: null, endTime: null, requestId: null, contestantId: null, testNotLimited: false, taskVariants: [] },
      startTimeRequest: { isOpen: false, requestId: null, times: null },
      errorModal: { isOpen: false, text: null, returnUrl: null },
      resultErrors: { isOpen: false, errors: [] },
      awardSettings: { isOpen: false, settings: null },
      selectContestants: { isOpen: false, isExistsRequest: false },
      setSchools: { isOpen: false, selectedSchools: [] },
      setCoordinatorSchools: { isOpen: false, selectedSchools: [] },
      tempSelectedCoordinatorSchools: [],
      coordinatorEditSchool: { isOpen: false, selectedSchoolId: null },
      coordinatorEditCity: { isOpen: false, selectedCityId: null, regionId: null, districtId: null },
      coordinatorEditDistrict: { isOpen: false, selectedDistrictId: null, regionId: null },
      notificationUsers: { isOpen: false, selectedUsers: [] },
      contestants: [],
      newTeacherVariant: null,
      newTasksForTeacherVariant: [],
      removedTasksForTeacherVariant: [],

      setError: this.setError,
      clearError: this.clearError,
      showModalError: this.showModalError,
      hideModalError: this.hideModalError,
      showLoader: this.showLoader,
      hideLoader: this.hideLoader,
      showApplyRegisterRequestModal: this.showApplyRegisterRequestModal,
      hideApplyRegisterRequestModal: this.hideApplyRegisterRequestModal,
      showCreateBlockVariant: this.showCreateBlockVariant,
      hideCreateBlockVariant: this.hideCreateBlockVariant,
      showContestantCreate: this.showContestantCreate,
      hideContestantCreate: this.hideContestantCreate,
      showModalShareLinkGroupRequest: this.showModalShareLinkGroupRequest,
      hideModalShareLinkGroupRequest: this.hideModalShareLinkGroupRequest,
      updatePlayerData: this.updatePlayerData,
      selectPlayerTask: this.selectPlayerTask,
      showSetTimeRequest: this.showSetTimeRequest,
      hideSetTimeRequest: this.hideSetTimeRequest,
      showResultErrors: this.showResultErrors,
      hideResultErrors: this.hideResultErrors,
      showAwardSettings: this.showAwardSettings,
      hideAwardSettings: this.hideAwardSettings,
      showSelectContestants: this.showSelectContestants,
      hideSelectContestants: this.hideSelectContestants,
      setContestants: this.setContestants,
      clearContestants: this.clearContestants,
      showSetSchools: this.showSetSchools,
      hideSetSchools: this.hideSetSchools,
      showCoordinatorSetSchools: this.showCoordinatorSetSchools,
      hideCoordinatorSetSchools: this.hideCoordinatorSetSchools,
      showCoordinatorEditSchool: this.showCoordinatorEditSchool,
      hideCoordinatorEditSchool: this.hideCoordinatorEditSchool,
      showCoordinatorEditCity: this.showCoordinatorEditCity,
      hideCoordinatorEditCity: this.hideCoordinatorEditCity,
      showCoordinatorEditDistrict: this.showCoordinatorEditDistrict,
      hideCoordinatorEditDistrict: this.hideCoordinatorEditDistrict,
      updateCoordinatorSetSchools: this.updateCoordinatorSetSchools,
      selectSchool: this.selectSchool,
      showSetNotificationUsers: this.showSetNotificationUsers,
      hideSetNotificationUsers: this.hideSetNotificationUsers,
      selectCoordinatorSchool: this.selectCoordinatorSchool,
      saveNewTeacherVariant: this.saveNewTeacherVariant,
      saveNewTasksForTeacherVariant: this.saveNewTasksForTeacherVariant
    };

    this.fillTawkData = () => {
      if (this.state.user && this.tawkMessengerRef.current && this.state.isTawkLoaded) {
        var user = this.state.user;

        this.tawkMessengerRef.current.setAttributes({
          name: `${user.lastName} ${user.firstName} ${user.middleName}`,
          email: `${user.email}`,
          phone: `+${user.phone}`
        }, function (error) {
          // do something if error
          //alert(error);
        });
      }
    }

    this.onLoad = () => {
      this.setState({ isTawkLoaded: true })
      this.fillTawkData();
    };
  }

  componentDidMount() {
    auth.currentUser.subscribe(x => {
      this.setState({ currentUser: x })
    });

    userService.currentUser.subscribe(user => {
      this.setState({ user });

      this.fillTawkData();
    });
  }
  render() {
    const paymentType = window['runConfig']?.paymentType || 2;
    return (
      <div>
        <GlobalContext.Provider value={this.state}>
          <YMInitializer accounts={[57046738]} />
          <TawkMessengerReact
            propertyId="633ee53e37898912e96d376c"
            widgetId="1gemquvnt"
            ref={this.tawkMessengerRef}
            onLoad={this.onLoad}
          />
          <HashRouter history={history}>
            <React.Suspense fallback={loading()}>
              <Switch>

                <Route exact path="/teacher/request/group/:id/report" name="Вариант" render={props => <TeacherReport {...props} />} />
                <Route exact path="/variants/preview/:id" name="Вариант" render={props => <PublicVariantPreview {...props} />} />
                {paymentType == 1 && <Route exact path="/public/payment/:id" name="Внешняя оплата" render={props => <YandexKassaPublic {...props} />} /> }
                {paymentType == 2 && <Route exact path="/public/payment/:id" name="Внешняя оплата" render={props => <TinkoffKassaPublic {...props} />} />}
                {paymentType == 1 && <Route exact path="/request/group/:id/external/payment" name="Внешняя оплата" render={props => <YandexKassaPublic {...props} />} />}
                {paymentType == 2 && <Route exact path="/request/group/:id/external/payment" name="Внешняя оплата" render={props => <TinkoffKassaPublic {...props} />} />}
                <Route exact path="/successPayment" name="External Participation" render={props => <SuccessPayment {...props} />} />
                <Route exact path="/successPayment/public" name="External Participation" render={props => <SuccessPaymentPublic {...props} />} />
                <Route exact path="/external/success" name="External Profile" render={props => <ExternalProfile {...props} />} />
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Route exact path="/forgotpassword" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
                <Route exact path="/password/change" name="Set Password" render={props => <ChangePassword {...props} />} />
                <Route exact path="/password/restore" name="Restore Password" render={props => <RestorePassword {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/preregister" name="PreRegister Page" render={props => <PreRegister {...props} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route path="/player" name="Player" render={props => <PlayerLayout {...props} />} />
                <Route path="/payment/external/multiple/:categoryId" name="External Participation" render={props => <ExternalPaymentMultiple {...props} />} />
                <Route path="/payment/external" name="External Participation" render={props => <ExternalParticipation {...props} />} />
                <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />

              </Switch>
            </React.Suspense>
          </HashRouter>
        </GlobalContext.Provider>
      </div>
    );
  }
}

// ... definition of App class ...
export default App;
//export default App;


