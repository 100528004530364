//import config from 'config';
import {  Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import { get, post, postFile, remove } from "../_methods/download";
import { auth } from "../_services";

var user = localStorage.getItem('currentUserData') ? localStorage.getItem('currentUserData') : null;
const currentUserSubject = new BehaviorSubject(JSON.parse(user));

export const userService = {
  getCurrentUser,
  getUser,
  getUsers,
  updateUser,
  updatePassword,
  refreshUser,
  getUserByPhone,
  getUserByEmail,
  getUserById,
  getProfileSettings,
  getProfileSettingsGeo,
  attachPhone,
  detachPhone,
  sendSms,
  checkSms,
  getRegions,
  getDistricts,
  getCities,
  getSchools,
  getRegionsGeo,
  getDistrictsGeo,
  getCitiesGeo,
  getSchoolsGeo,
  attachPhoneConfirm,
  detachPhoneConfirm,
  sendSmsForRegister,
  checkSmsForRegister,
  createOrganizer,
  isAdmin,
  currentUser: currentUserSubject.asObservable(),
  lastUpdatedTime,
  startTest,
  getContestsInvites,
  contestStart,
  getContestantByPhone,
  getContestantByEmail,
  setUserRole,
  isSchoolOrganizer,
  sendRegistrationEmail,
  uploadSchoolAdmins,
  setSchoolsForCoordinator,
  clearCoordinatorSchools,
  setNewSchoolsForCoordinator,
  removeSchoolForCoordinator,
  getSchoolsForCoordinator,
  selectAllSchoolsByFilters,
  getCoordinatorRegions,
  getCoordinators,
  confirmRole,
  checkRole,
  get currentUserValue() {
    return currentUserSubject.value ? currentUserSubject.value : (async () => {
      let user = await getUser();
      return user ? user.data : null;
    })()},
  get currentUserAsync() { return (async () => { return await getUser()})(); }
};

function lastUpdatedTime() {
  return userService.currentUserValue != null ? userService.currentUserValue.lastUpdatedTime : null;
}

async function updateUser(model) {
  const url = `api/account/edit`;
  return await post(url, true, model);
}

async function updatePassword(model) {
  var response = await post(`api/account/changePassword`, true, model);
  return response;
}

async function refreshUser(clear = false) {
  if (clear) 
    currentUserSubject.next(null);

  return clear ? null : await getUser(true);
}

async function isAdmin() {
  if (!userService.currentUserValue)
    await refreshUser();
  return userService.currentUserValue ? userService.currentUserValue.isAdmin : false;
}

async function isSchoolOrganizer() {
  if (!userService.currentUserValue)
    await refreshUser();
  return userService.currentUserValue ? userService.currentUserValue.isSchoolOrganizer : false;
}

async function getProfileSettings(regionId) {
  let regions = await getRegions();
  let districts = regionId ? await getDistricts(regionId) : {data:[]};
  let cities = regionId ? await getCities(regionId) : { data: [] };
  let schools = regionId ? await getSchools(regionId) : { data: [] };
  let response = {
    data: {
      regions: regions.data,
      districts: districts.data,
      cities: cities.data,
      schools: schools.data
    }
  };
  return response;
}

async function getProfileSettingsGeo(regionId) {
  let regions = await getRegionsGeo();
  let districts = regionId ? await getDistrictsGeo(regionId) : { data: [] };
  let cities = regionId ? await getCitiesGeo(regionId) : { data: [] };
  let schools = regionId ? await getSchoolsGeo(regionId) : { data: [] };
  let response = {
    data: {
      regions: regions.data,
      districts: districts.data,
      cities: cities.data,
      schools: schools.data
    }
  };
  return response;
}

async function getPublicProfileSettings(regionId, districtId, cityId) {
  let regions = await getRegions();
  let districts = regionId ? await getDistricts(regionId) : { data: [] };
  let cities = regionId ? await getCities(regionId) : { data: [] };
  let schools = regionId ? await getSchools(regionId) : { data: [] };
  let response = {
    data: {
      regions: regions.data,
      districts: districts.data,
      cities: cities.data,
      schools: schools.data
    }
  };
  return response;
}

async function getRegions() {
  let response = await get(`api/profile/regions`, true);
  return response;
}

async function getDistricts(regionId, withNew = true) {
  let response = await get(`api/profile/provinces?region=${regionId}&withNew=${withNew}`, true);
  return response;
}

async function getCities(provinceId, regionId, withNew = true) {
  provinceId = provinceId ? provinceId : "";
  regionId = regionId ? regionId : "";
  let response = await get(`api/profile/districts?province=${provinceId}&region=${regionId}&withNew=${withNew}`, true);
  return response;
}


async function getSchools(regionId, districtId, cityId) {
  regionId = regionId ? regionId : "";
  districtId = districtId ? districtId : "";
  cityId = cityId ? cityId : "";
  let response = await get(`/api/profile/schoolsGroupByCity?region=${regionId}&district=${districtId}&city=${cityId}&deleted=false`, true);
  return response;
}

async function getRegionsGeo() {
  let response = await get(`api/profile/geoProvinces`, true);
  return response;
}

async function getDistrictsGeo(regionId) {
  let response = await get(`api/profile/geoRegions?province=${regionId}`, true);
  return response;
}

async function getCitiesGeo(provinceId, regionId) {
  provinceId = provinceId ? provinceId : "";
  regionId = regionId ? regionId : "";
  let response = await get(`api/profile/geoDistricts?province=${provinceId}&region=${regionId}`, true);
  return response;
}


async function getSchoolsGeo(regionId, districtId, cityId) {
  regionId = regionId ? regionId : "";
  districtId = districtId ? districtId : "";
  cityId = cityId ? cityId : "";
  let response = await get(`/api/profile/geoSchoolsGroupByCity?province=${regionId}&region=${districtId}&district=${cityId}&deleted=false`, true);
  return response;
}

async function getCurrentUser() {
    if (userService.currentUserValue != null) {
      return userService.currentUserValue;
    }

    var userFromApi = null;
    let url = `/api/account/me`;
    await Fetch(url, null, true, false)
        .then(response => {
            if (response) return response;
            else {
                let error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => response.json())
        .then(
            async response => {
                userFromApi = response;
                currentUserSubject.next(response.data);
                localStorage.setItem('currentUserData', JSON.stringify(response.data));
            })
        .catch(error => {
          return { error: `Url: ${url}. Error: ${error && error.message ? error.message : error}.` }
        });

  return userFromApi;
}

async function getUserByPhone(phone) {
  var user = await get(`/api/auth/searchByPhone/${phone}`, false);
  return user;
}

async function getUserByEmail(email) {
  var user = await post(`/api/auth/searchByEmail`, false, JSON.stringify({ email: email }));
  return user;
}

async function getContestantByPhone(phone) {
  var user = await get(`/api/account/searchByPhone/${phone}`, true);
  return user;
}

async function getContestantByEmail(email) {
  var user = await post(`/api/account/searchByEmail`, true, JSON.stringify({email: email}));
  return user;
}

async function getUser() {
  var user = await get(`/api/account/me`, true);
  if (user && !user.error)
    currentUserSubject.next(user.data);
  return user;
}

async function getUserById(id) {
  var user = await get(`/api/account/${id}`, true);
  return user;
}

async function setUserRole(userId, role) {
  var user = await post(`/api/accounts/${userId}/setRole/${role}`, true);
  return user;
}

async function getUsers(type, page, size, filter) {
  let role = filter && filter.role ? `&role=${filter.role.value}` : "";
  let userData = filter && filter.userData ? `&u=${filter.userData}` : "";
  let region = filter && filter.region ? `&r=${filter.region}` : "";
  let school = filter && filter.school ? `&s=${filter.school}` : "";
  let schoolMasks = filter && filter.schoolMasks ? `&sm=${filter.schoolMasks.replace(/\s/g, '')}` : "";
  let deleted = filter.role && filter.role.value == 6 ? `&deleted=false` : "";
  
  var users = await get(`/api/accounts?page=${page}&pageSize=${size}${role}${userData}${region}${school}${schoolMasks}${deleted}`, true);
  return users;
}

async function createOrganizer(model) {
  return await post(`/api/organizer/create`, true, model);
}

async function attachPhone(model) {
  return await post(`/api/account/attachPhone`, true, JSON.stringify(model));
}

async function attachPhoneConfirm(model) {
  return await post(`/api/account/attachPhoneConfirm`, true, JSON.stringify(model));
}

async function detachPhone(model) {
  return await post(`/api/account/detachPhone`, true, JSON.stringify(model));
}

async function detachPhoneConfirm(model) {
  return await post(`/api/account/detachPhoneConfirm`, true, JSON.stringify(model));
}

async function sendSms() {
  return await post(`/api/account/sendsmsCode`, true);
}

async function checkSms(model) {
  return await post(`/api/account/checksmsCode`, false, model);
}

async function sendSmsForRegister(model) {
  return await post(`/api/auth/sendSmsForRegister`, false, model);
}

async function getContestsInvites() {
  return await get(`/api/account/getContestsInvites`, true);
}

async function sendRegistrationEmail(id) {
  return await get(`/api/accounts/${id}/sendRegistrationEmail`, true);
}

async function uploadSchoolAdmins(model) {
  return await postFile(`/api/accounts/uploadSchoolAdmins`, true, model);
}

async function checkSmsForRegister(model, isLogin = false) {
  const response = await post(`/api/auth/checkSmsForRegister`, false, model);
  if (response && response.success) {
    const authObj = {
      expires_in: response.data.expires_in,
      expires_in_timestamp: response.data.expires_in_timestamp,
      refreshToken: response.data.refreshToken,
      access_token: response.data.access_token,
      refreshTokenExpires: response.data.refreshTokenExpires
    };
    localStorage.setItem('currentAuth', JSON.stringify(authObj));
    await auth.changeAuthValue(authObj);
    return response;
  } else
    return response;

}

async function contestStart(id) {
  return await get(`api/contestants/${id}/start`, true);
}

async function setSchoolsForCoordinator(userId, model) {
  return await post(`/api/accounts/${userId}/setCoordinatorSchools`, true, JSON.stringify(model));
}

async function setNewSchoolsForCoordinator(coordinatorId, schools) {
  let model = { schools };
  return await post(`/api/accounts/${coordinatorId}/setCoordinatorSchools`, true, JSON.stringify(model));
}

async function getSchoolsForCoordinator(page, pageSize, filter, isNew = false) {
  let region = filter && filter.regionCode ? `&p=${filter.regionCode}` : "";
  let city = filter && filter.cityCode ? `&d=${filter.cityCode}` : "";
  let school = filter && filter.school ? `&school=${filter.school}` : "";
  let group = filter && filter.group ? `&group=${filter.group}` : "";
  let isNewFilter = isNew ? `&isNew=${true}` : "";
  let except = filter?.except && filter.except.length > 0 ? `&except=${filter.except.join(",")}` : "";
  return await get(`/api/account/coordinatorSchools?page=${page}&pageSize=${pageSize}${region}${city}${school}${group}${isNewFilter}${except}`, true);
}

async function removeSchoolForCoordinator(id) {
  return await remove(`/api/account/coordinatorSchools/${id}`, true);
}

async function selectAllSchoolsByFilters(coordinatorId, filter) {
  let model = {};
  if (filter && filter.schoolCode)
    model.schoolCode = filter.schoolCode;
  if (filter && filter.regionCode)
    model.regionCode = filter.regionCode;
  if (filter && filter.districtCode)
    model.districtCode = filter.districtCode;
  if (filter && filter.cityCode)
    model.cityCode = filter.cityCode;
  if (filter)
    model.isGeo = filter.isGeo;

  return await post(`/api/accounts/${coordinatorId}/setFilteredCoordinatorSchools?isGeo=${model.isGeo}`, true, JSON.stringify(model));
}

async function startTest() {
  return {
    data: { startTime: "2020-12-18 10:00:00", endTime: "2020-12-18 18:00:00", contestName: "Смарт кенгуру смарт кенгуру", numberOfTasks: 40 }
  };
  return await post(`/api/account/startTest`, false/*, model*/);
}

async function getCoordinatorRegions() {	
	var user = await getCurrentUser();
	
	/*return {
		data: {"regions":[{"id":1,"name":"\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0410\u0434\u044b\u0433\u0435\u044f","code":"01","value":1,"label":"\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0410\u0434\u044b\u0433\u0435\u044f"}],"districts":[{"id":1,"name":"\u041c\u0430\u0439\u043a\u043e\u043f","code":"010","value":1,"label":"\u041c\u0430\u0439\u043a\u043e\u043f"}],"cities":[{"id":1,"name":"\u0433. \u041c\u0430\u0439\u043a\u043e\u043f","code":"01","value":1,"label":"\u0433. \u041c\u0430\u0439\u043a\u043e\u043f"}]}		
	};*/
    return await get(`api/coordinators/${user.id}/schoolsUniqueLocations`, true);
}

async function getCoordinators() {	
  return await get(`api/accounts/coordinators/noSchools`, true);
}

async function clearCoordinatorSchools(id) {	
  return await post(`api/admin/coordinators/${id}/clearSchools`, true);
}

async function confirmRole() {
  return await post(`api/account/roleSelected`, true);
}

async function checkRole() {
  return await get(`api/accounts/checkRole`, true);
}

export default userService;
