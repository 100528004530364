//Staff management service, i.e. edit/add/delete reset pw by admin etc.

//import config from 'config';
import { get, post, put, remove, postFile } from "../_methods/download";

export const contestService = {
  getGroupApplications,
  getContest,
  getContests,
  getAdminContests,
  createAdminContest,
  updateAdminContest,
  removeAdminContests,
  getAdminContest,
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
  getContestsByCategoryId,
  getAdminCategories,
  publishCategory,
  archiveCategory,
  publishContest,
  archiveContest,
  uploadOfflineVariants,
  getContestResults,
  getTopContestants,
  calculateResults,
  contestResultsLink,
  uploadResults,
  getUploadedResults,
  getAwardWinners,
  updateContestAwardSettings,
  updateContestAwardSettingsByLocation,
  calculateAwardWinners,
  getTemplateFormats,
  getContestCategoriesAdmin,
  clearCertificate,
  clearDiploma,
  getContestsByReportCategoryId,
  getContestsByReportCategoryIdFull,
  getParams,
  previewCertificate,
  previewDiploma,
  uploadCertificateTemplate,
  uploadDiplomaTemplate,
  getCategoriesIndividual,
  getContestsForSeries,
  uploadOfflineResultsTemplate,
  updatePdfLinks,
  getVariantUploadStatus,
  uploadLetterTemplate,
  copyContest,
  copyCategory,
  createContestDb,
  lockContest,
  unlockContest,
  createAdminContestArchive,
  updateAdminContestArchive,
  uploadArchive,
  uploadLetterImage
};

async function getGroupApplications() {
  //return groupApplicationsData;
  return await get(`/api/groupApplications`, true);
}

async function getContests(type, page, pageSize, filter, individualParticipation = false, isNew = false) {
  let categoryId = filter ? filter.category ? `&categoryId=${filter.category}` : (typeof filter != "object") ? `&categoryId=${filter}` : '' : '';
  let isArchive = filter && (typeof filter.isArchive == "boolean") ? `&isArchive=${filter.isArchive}` : "";
  return isNew ? individualParticipation ? await get(`api/contests/studentList?status=${type}&page=${page}&pageSize=${pageSize}${categoryId}&individualParticipation=${individualParticipation}${isArchive}`, true)
    : await get(`api/contests/teacherList?status=${type}&page=${page}&pageSize=${pageSize}${categoryId}&individualParticipation=${individualParticipation}${isArchive}`, true)
    : await get(`/api/contests?status=${type}&page=${page}&pageSize=${pageSize}${categoryId}&individualParticipation=${individualParticipation}${isArchive}`, true)
}

async function getContestsForSeries(categoryId) {
  return await get(`/api/contestSeriesSubscriptions/contests/${categoryId}`, true);
}

async function getContest(id) {
  return await get(`/api/contests/${id}`, true);
}

async function getContestsByCategoryId(categoryId, fillOverrides = false, isArchive = null) {
  let fillOverridesFilter = fillOverrides ? `&fillOverrides=${fillOverrides}` : "";
  let isArchiveFilter = isArchive != null ? `&isArchive=${isArchive}` : "";
  return await get(`/api/contests?category=${categoryId}${isArchiveFilter}${fillOverridesFilter}`, true);
}
async function getContestsByReportCategoryId(categoryId) {
  return await get(`/api/contests/getByReportCategoryId/${categoryId}`, true);
}

async function getContestsByReportCategoryIdFull(categoryId) {
  return await get(`/api/contests/getByReportCategoryId2/${categoryId}`, true);
}

async function getAdminContests(type, page, pageSize, filter, fillOverrides = false) {
  let categoryId = filter && filter.category ? filter.category : '';
  let fillOverridesFilter = fillOverrides ? `&fillOverrides=${fillOverrides}` : "";
  return await get(`/api/contests/admin?status=${type}&page=${page}&pageSize=${pageSize}&category=${categoryId}${fillOverridesFilter}`, true);
}

async function getAdminCategories(type, page, pageSize) {
  //return categoriesData;
  return await get(`/api/categories?type=${type}&page=${page}&pageSize=${pageSize}`, true);
}

async function getAdminContest(id) {
  return await get(`/api/contests/${id}`, true);
}

async function createAdminContest(model) {
  return await post(`/api/contests`, true, model);
}

async function createAdminContestArchive(model) {
  return await post(`/api/contests`, true, model);
}

async function updateAdminContest(id, model) {
  return await put(`/api/contests/${id}`, true, model);
}

async function updateAdminContestArchive(id, model) {
  return await put(`/api/contests/${id}`, true, model);
}

async function removeAdminContests(id) {
  return await remove(`/api/contests/${id}`, true);
}

async function getCategories(type, page, pageSize, isGroup = false) {
  let isGroupFilter = `&individualParticipation=${isGroup}`;
  return await get(`/api/contestCategories/schoolManagerList?status=${type}&page=${page}&pageSize=${pageSize}${isGroupFilter}`, true);
  //return await get(`/api/contestCategories?status=${type}&page=${page}&pageSize=${pageSize}${isGroupFilter}`, true);
}

async function getCategoriesIndividual() {
  return await get(`api/contestCategories/individualList`, true);
}

async function getContestCategoriesAdmin(type, page, pageSize) {
  return await get(`/api/contestCategories/admin?status=${type}&page=${page}&pageSize=${pageSize}`, true);
}

async function getCategory(id) {
  return await get(`/api/contestCategories/${id}`, true);
}

async function createCategory(model) {
  return await post(`/api/contestCategories`, true, model);
}

async function updateCategory(id, model) {
  return await put(`/api/contestCategories/${id}`, true, model);
}

async function removeCategory(id) {
  return await remove(`/api/contestCategories/${id}`, true);
}

async function publishCategory(id) {
  return await get(`/api/contestCategories/${id}/publish`, true);
}

async function archiveCategory(id) {
  return await get(`/api/contestCategories/${id}/archive`, true);
}

async function publishContest(id) {
  return await post(`/api/contests/${id}/publish `, true, JSON.stringify({}));
}

async function archiveContest(id) {
  return await post(`/api/contests/${id}/archive`, true, JSON.stringify({}));
}

async function calculateResults(id) {
  return await get(`/api/contests/${id}/calculate`, true);
}

function contestResultsLink(id) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/contests/${id}/downloadResults?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function previewCertificate(contestId, fields) {
  let model = {
    params: { rows: fields }
  };
  let preview = await post(`/api/contests/${contestId}/previewCertificate`, true, JSON.stringify(model), false, null, true);
  return preview;
}

async function previewDiploma(contestId, fields) {
  let model = {
    params: { rows: fields }
  };
  let preview = await post(`/api/contests/${contestId}/previewDiploma`, true, JSON.stringify(model), false, null, true);
  return preview;
}

async function uploadOfflineVariants(contestId, model) {
  debugger;
  return await postFile(`/api/contests/${contestId}/uploadVariant`, true, model);
}

async function uploadResults(contestId, type, locationId, model) {
  locationId = locationId ? `${locationId}` : "null";
  return await postFile(`/api/contests/${contestId}/uploadResults?type=${type}&locationId=${locationId}`, true, model);
}

async function uploadArchive(contestId, model) {
  //return await postFile(`/api/contests/${contestId}/uploadArchive`, true, model);
  return await postFile(`/api/contests/${contestId}/uploadResults?type=0&locationId=null`, true, model);
}

async function getTopContestants(contestId, type, locationId) {
  locationId = locationId ? `${locationId}` : "null";
  type = type > 0 ? `${type}` : 1;
  return await get(`/api/contests/${contestId}/top/${type}/${locationId}`, true);
}

async function getAwardWinners(contestId, type, locationId) {
  locationId = locationId ? `${locationId}` : "null";
  return await get(`/api/contests/${contestId}/awardWinners/${type}/${locationId}`, true);
}

async function calculateAwardWinners(contestId, type, locationId, awardWinnerPercent) {
  locationId = locationId ? `${locationId}` : null;
  type = type && type >= 1 ? type : null;
  let model = { locationId, type };
  return await post(`/api/contests/${contestId}/awardWinners/calculate`, true, JSON.stringify(model));
  //api/contests/15/awardWinners/calculate
}

async function updateContestAwardSettings(contestId, awardWinnerPercentRegion, awardWinnerPercentDistrict) {
  let model = { awardWinnerPercentRegion, awardWinnerPercentDistrict };
  return await post(`/api/contests/${contestId}/updateContestAwardSettings`, true, JSON.stringify(model));
  //api/contests/15/awardWinners/calculate
}

async function updateContestAwardSettingsByLocation(contestId, type, locationId, awardWinnerPercent) {
  locationId = locationId ? `${locationId}` : null;
  type = type && type >= 1 ? type : null;
  let model = { locationId, type, percent: awardWinnerPercent };
  return await post(`/api/contests/${contestId}/updateContestAwardSettingsByLocation`, true, JSON.stringify(model));
  //api/contests/15/awardWinners/calculate
}

async function getContestResults(contestId, type, locationId, page, pageSize, filter) {
  const regionCode = filter && filter.regionCode ? filter.regionCode : "";
  const districtCode = filter && filter.districtCode ? filter.districtCode : "";
  const cityCode = filter && filter.cityCode ? filter.cityCode : "";
  locationId = locationId ? `${locationId}` : "null";
  type = type > 0 ? `${type}` : 1;

  return type
    ? await get(`api/contests/${contestId}/results/${type}/${locationId}?page=${page}&pageSize=${pageSize}&rc=${regionCode}&dc=${districtCode}&cc=${cityCode}`, true)
    : await get(`api/contests/${contestId}/results?page=${page}&pageSize=${pageSize}&rc=${regionCode}&dc=${districtCode}&cc=${cityCode}`, true);
}

async function getUploadedResults(contestId, type, locationId, page, pageSize, filter) {
  locationId = locationId ? `${locationId}` : "null";
  return await get(`/api/contests/${contestId}/uploadedResults?type=${type}&locationId=${locationId}&page=${page}&pageSize=${pageSize}`, true);
}

async function getTemplateFormats() {
  return await get(`/api/contests/certificatesDiplomasFormats`, true);
}
async function clearCertificate(id) {
  return await post(`/api/contests/${id}/clearCertificateTemplate`, true, JSON.stringify({}));
}
async function clearDiploma(id) {
  return await post(`/api/contests/${id}/clearDiplomaTemplate`, true, JSON.stringify({}));
}

async function getParams() {
  return await get(`/api/contests/settings`, true);
}

async function uploadCertificateTemplate(contestId, model) {
  return await postFile(`/api/contests/${contestId}/uploadCertificateTemplate`, true, model);
}

async function uploadDiplomaTemplate(contestId, model) {
  return await postFile(`/api/contests/${contestId}/uploadDiplomaTemplate`, true, model);
}

async function uploadOfflineResultsTemplate(contestId, model) {
  return await postFile(`/api/contests/${contestId}/uploadOfflineResultsTemplate`, true, model);
}

async function updatePdfLinks(contestId) {
  return await post(`api/contests/${contestId}/updatePdf`, true);
}

async function getVariantUploadStatus(id) {
  return await get(`/api/contests/${id}/variantStatus`, true);
}
async function uploadLetterTemplate(contestId, model) {
  return await postFile(`/api/contests/${contestId}/uploadLetterTemplate`, true, model);
}

async function copyContest(contestId) {
  return await post(`/api/contests/${contestId}/copy`, true);
}

async function copyCategory(categoryId) {
  return await post(`/api/contestCategories/${categoryId}/copy`, true);
}

async function createContestDb(name, connectionString) {
  let model = { name, connectionString };
  return { data: true };
  return await post(`/api/contests/createDb`, true, model);
}

async function lockContest(id) {
  return await post(`/api/contests/${id}/lock`, true);
}

async function unlockContest(id) {
  return await post(`/api/contests/${id}/unlock`, true);
}

async function uploadLetterImage(file, fileName) {
  return await postFile(`/api/tasks/uploadImage`, true, file);
}
