
export const categoryTypes = [
  { label: "Категория", value: 1 },
  { label: "Серия", value: 2 }
];

export const seriesPaymentPeriods = [
  { label: "Единоразово", value: 1 },
  { label: "Раз в неделю", value: 2 },
  { label: "Раз в месяц", value: 3 }
];

export const seriesRegistrationAvailableForTypes = [
  { label: "Ученику", value: 20 },
  { label: "Учителю", value: 30 },
  { label: "Всем", value: 10 }
];

export const paymentFormats = [
  {
    label: "На сайте",
    value: 2
  }, {
    label: "Вне системы/Через координатора",
    value: 1
  }
];

export const availableForTypes = [
  {
    label: "Всем кроме",
    value: 10
  }, {
    label: "Выбранным субъектам",
    value: 20
  }
];

export const offlineResultsSmearData = [
  {
    label: "В заявку",
    value: false
  },
  {
    label: "По всем заявкам школы",
    value: true
  }
];

export const oneReportForCategoryData = [
  {
    label: "Только данные текущего конкурса",
    value: false
  },
  {
    label: "Конкурсы по всей категории",
    value: true
  }
];

export const formats = [
  {
    label: "Онлайн",
    value: 1
  }, {
    label: "Офлайн",
    value: 2
  }, {
    label: "Онлайн или Офлайн",
    value: 3
  }
];

export const participationFormats = [
  {
    label: "Группа",
    value: 1
  }, {
    label: "Индивидуально",
    value: 2
  }, {
    label: "Группа+Индивидуально",
    value: 3
  }, {
    label: "Учитель как участник",
    value: 4
  }, {
    label: "Учитель и ученик как участники",
    value: 5
  }
];

export const publicationStatuses = [
  {
    label: "Нет",
    value: 1
  }, {
    label: "Частично",
    value: 2
  }, {
    label: "Полностью",
    value: 3
  }
];

export const archiveAccess = [
  { label: "Всем", value: 1 },
  { label: "Тем, кто приобрел до публикации", value: 2 },
];

export const availableForData = [
  { label: "Никому", value: 1 },
  { label: "Учителю", value: 2 },
  { label: "Учителю и ученику", value: 3 },
];

export const displayingCorrectAnswersFormats = [
  { label: `Показывать верный ответ\\ответ студента`, value: 10 },
  { label: `Показывать верно\\неверно (плюс\\минус)`, value: 20 }
];

export const reportAnswerTypes = [
  { label: "Показывать ответ как А,Б,В,Г", value: 10 },
  { label: "Показывать сам ответ", value: 20 }
];

export const reportAvailableFor = [
  { label: "Учителю только по своим заявкам", value: 10 },
  { label: "Учителю по заявкам всей школы", value: 20 }
];

export const activityStatuses = [
  { label: "Активный", value: 2 },
  { label: "Архивный", value: 3 }
];

export const historyObjects = [
  {
    label: "Все",
    value: null
  },
  {
    label: "Отчетная категория",
    value: 'reportCategory'
  },
  {
    label: "Категория",
    value: 'contestCategory'
  },
  {
    label: "Конкурс",
    value: "contest"
  },

  {
    label: "Школа",
    value: "school"
  },
  {
    label: "Пользователь",
    value: "user"
  },


  {
    label: "Вариант заданий",
    value: "variant"
  },
  {
    label: "Задание",
    value: "task"
  },
  {
    label: "Онлайн платеж",
    value: "onlinePayment"
  },
  {
    label: "Платеж",
    value: "payment"
  },
  {
    label: "Детали платежа",
    value: "paymentDetail"
  },
];

export const certificateAvailableFor = [
  { label: "Всем", value: 10 },
  { label: "Если набрано баллов % от максимального более чем", value: 20 }
];

