import { get, post, put, remove, postFile } from "../_methods/download";

export const requestService = {
  createRequest,
  getRequest,
  getAdminGroupRequests,
  getAdminGroupRequest,
  getAdminRegisterRequests,
  getAdminRegisterRequest,
  getAdminIndividualRequests,
  getAdminIndividualRequest,
  setRequestStatus,

  getSchoolAdminRegisterRequests,
  setOrganizerRequestStatus,

  getStudentGroupRequests,
  getTeacherGroupRequests,
  getCoordinatorGroupRequests,

  createGroupRequest,
  updateGroupRequest,

  getTeacherContestants,
  getCoordinatorContestants,
  getStudentContestants,
  createContestant,
  removeContestant,
  inviteContestant,
  inviteContestants,
  getRequestByToken,
  joinToRequestById,
  approveApplication,

  getContestant,
  updateContestant,
  getAvailableFormats,

  getTeacherGroupRequestResults,
  getStudentGroupRequestResults,

  uploadResults,
  downloadVariants,
  setStartEndTime,
  setTeacherTestDuration,
  downloadResultsTemplate,
  downloadGeneratedVariants,
  downloadVariantsFromContest,

  getStudentGroupRequestSolution,
  getTeacherGroupRequestSolution,
  printCertificates,
  printDiplomas,
  printThankYouLetter,
  printReport,
  getPaymentStatus,
  payAdminRequest,
  copyContestants,
  addContestants,
  addContestant,
  copyContestant,
  getSeriesContests,
  getSeries,
  clearResults,
  createRequestEx,
  uploadAllResults,
  inviteContestantsCoordinator,
  getCoordinatorByRequestId,
  getPaymentsForRequest,
  getContestantsGroupedByRequest,
  createExternalPayment,
  createTeacherRequest,
  createReportParams,
  getReportData
};

async function getRequest(id) {
  return await get(`/api/requests/group/${id}`, true);
}

async function getSeries(id) {
  //return { data: series[0] };
  return await get(`/api/series/${id}`, true);
}

async function getAdminRegisterRequests(isArchive = false, page, pageSize) {
  return await get(`/api/admin/request/register/list?isArchive=${isArchive}&page=${page}&pageSize=${pageSize}`, true);
}

async function getAdminRegisterRequest(id) {
  return await get(`/api/admin/groupRequests/${id}`, true);
}

async function getAdminIndividualRequests() {
  return await get(`/api/admin/requests/individual`, true);
}

async function getAdminIndividualRequest(id) {
  return await get(`/api/admin/individualRequests/${id}`, true);
}

async function getAdminGroupRequests(isArchive = false, page, pageSize, filter) {
  let contest = filter && filter.contest ? `&contest=${filter.contest}` : "";
  let region = filter && filter.region ? `&region=${filter.region}` : "";
  let city = filter && filter.city ? `&city=${filter.city}` : "";
  let school = filter && filter.school ? `&school=${filter.school}` : "";
  let user = filter && filter.user ? `&user=${filter.user}` : "";
  return await get(`/api/admin/requests/group/list?isArchive=${isArchive}&page=${page}&pageSize=${pageSize}${contest}${region}${city}${school}${user}`, true);
}

async function getAdminGroupRequest(id) {
  return await get(`/api/admin/requests/group/${id}`, true);
}

async function createRequest(model) {
  return await post(`/api/request/group/create`, true, model);
}

async function setRequestStatus(id, model) {
  return await post(`/api/admin/request/register/setStatus/${id}`, true, model);
}

async function setOrganizerRequestStatus(id, isAdmin, model) {
  return isAdmin
    ? await post(`/api/admin/organizer/registerRequests/setStatus/${id}`, true, model)
    : await post(`/api/organizer/registerRequests/setStatus/${id}`, true, model);
}

async function getSchoolAdminRegisterRequests(type, isAdmin, page, pageSize, filter) {
  const school = filter.schoolCode ? filter.schoolCode : "";
  return isAdmin
    ? await get(`/api/admin/organizer/registerRequests/${type}?page=${page}&pageSize=${pageSize}&schoolCode=${school}`, true)
    : await get(`/api/organizer/registerRequests/${type}?page=${page}&pageSize=${pageSize}&schoolCode=${school}`, true);
}

async function getTeacherGroupRequests(isResults, page, pageSize, isContestArchive = false, selectedTag = null) {
  let finished = isResults ? `&finished=true` : "";
  let tag = selectedTag ? `&year=${selectedTag}` : "";
  return await get(`/api/requests/group/list?page=${page}&pageSize=${pageSize}${finished}&isContestArchive=${isContestArchive}${tag}`, true);
}

async function getCoordinatorGroupRequests(isResults, page, pageSize) {
  return await get(`/api/requests/group/list/coordinator?page=${page}&pageSize=${pageSize}`, true);
}

async function getStudentGroupRequests(isResults, page, pageSize, isContestArchive = false, selectedTag = null) {
  let finished = isResults ? `&finished=true` : "";
  let tag = selectedTag ? `&year=${selectedTag}` : "";
  let requests = await get(`/api/requests/group/list/student?page=${page}&pageSize=${pageSize}${finished}&isContestArchive=${isContestArchive}${tag}`, true);
  return requests;
}

async function createGroupRequest(model) {
  return await post(`/api/requests/group/create`, true, model);
}

async function updateGroupRequest(requestId, model) {
  return await put(`/api/requests/group/${requestId}`, true, model);
}

async function getTeacherContestants(id, type, page, pageSize) {
  let deleted = `deleted=${type === 3}&`;
  return await get(`/api/requests/group/${id}/contestants/${type}?${deleted}page=${page}&pageSize=${pageSize}`, true);
}

async function getStudentContestants(id, type, page, pageSize) {
  return await get(`/api/requests/group/${id}/contestants/${type}/student?page=${page}&pageSize=${pageSize}`, true);
}

async function getCoordinatorContestants(id, type, page, pageSize) {
  let deleted = `deleted=${type === 3}&`;
  return await get(`/api/requests/group/${id}/contestants/${type}?${deleted}page=${page}&pageSize=${pageSize}`, true);
}

async function createContestant(id, model) {
  return await post(`/api/requests/group/${id}/contestants`, true, model);
}

async function inviteContestant(id, userId) {
  return await get(`/api/requests/group/${id}/invite/${userId}`, true);
}

async function inviteContestants(id, userIds) {
  return await get(`/api/requests/group/${id}/invite?users=${userIds}`, true);
}

async function inviteContestantsCoordinator(id, userIds) {
  return await get(`/api/coordinator/requests/group/${id}/invite?users=${userIds}`, true);
}

async function getRequestByToken(token) {
  return await get(`/api/requests/group/getByToken/${token}`, true);
}

async function joinToRequestById(groupApplicationId) {
  return await get(`/api/requests/group/join/${groupApplicationId}`, true);
}

async function approveApplication(groupApplicationId) {
  return await get(`/api/requests/group/approve/${groupApplicationId}`, true);
}

async function getContestant(id) {
  return await get(`/api/contestants/${id}`, true);
}

async function updateContestant(id, model) {
  return await put(`/api/contestants/${id}`, true, model);
}

async function getAvailableFormats(id) {
  return await get(`/api/requests/group/${id}/getAvailableFormats`, true);
}

async function removeContestant(id) {
  return await remove(`/api/contestants/${id}`, true);
}

async function getTeacherGroupRequestResults(requestId, type, isSchool) {
  return await get(`/api/requests/group/${requestId}/results?type=${type}&isSchool=${isSchool}`, true);
} 

async function getStudentGroupRequestResults(requestId) {
  return await get(`/api/requests/group/${requestId}/contestant/results/student`, true);
}

async function getStudentGroupRequestSolution(requestId) {
  //return await get(`/api/variants/58`, true);
  return await get(`/api/requests/group/${requestId}/contestant/solution`, true);
}

async function getTeacherGroupRequestSolution(requestId) {
  //let variant = await get(`/api/variants/58`, true);
  //return { data: [variant.data, variant.data] }
  return await get(`/api/requests/group/${requestId}/contestant/variants`, true);
}

async function uploadResults(requestId, model) {
  return await postFile(`/api/requests/group/${requestId}/uploadResults`, true, model);
}

async function uploadAllResults(model) {
  return await postFile(`api/uploadResults/all`, true, model);
}

async function downloadResultsTemplate(requestId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/requests/group/${requestId}/downloadResultsTemplate?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function downloadVariants(requestId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/requests/group/${requestId}/downloadVariant?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function downloadVariantsFromContest(contestId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/contests/${contestId}/downloadVariant?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function downloadGeneratedVariants(contestId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/contests/${contestId}/downloadGeneratedVariant?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function setStartEndTime(requestId, model) {
  return await post(`/api/requests/group/${requestId}/setStartEndTime`, true, model);
}

function printCertificates(requestId, isSchool, withoutTemplate = false) {
  let authData = localStorage.getItem("currentAuth");
  withoutTemplate = `&blank=${withoutTemplate}`;
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/requests/group/${requestId}/certificate?token=${authData.access_token}&entireSchool=${isSchool ? 1 : 0}${withoutTemplate}`;
    return link;
  }
  return null;
}

function printDiplomas(requestId, isSchool, withoutTemplate = false) {
  let authData = localStorage.getItem("currentAuth");
  withoutTemplate = `&blank=${withoutTemplate}`;
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/requests/group/${requestId}/diploma?token=${authData.access_token}&entireSchool=${isSchool ? 1 : 0}${withoutTemplate}`;
    return link;
  }
  return null;
}

function printThankYouLetter(requestId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/account/thank/you?groupApplicationId=${requestId}&token=${authData.access_token}`;
    return link;
  }
  return null;
}

function printReport(requestId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/requests/group/${requestId}/pdfReport?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function getPaymentStatus(requestId) {
  return await get(`/api/requests/group/${requestId}/paymentStatus`, true);
}

async function payAdminRequest(requestId) {
  return await get(`/api/requests/group/${requestId}/payAdmin`, true);
}

async function copyContestants(requestId, oldRequestId) {
  let model = { requestId: oldRequestId}
  return await post(`/api/requests/group/${requestId}/copyContestants`, true, JSON.stringify(model));
}

async function addContestants(requestId, ids) {
  let model = { requestId, contestants: ids }
  return await post(`/api/requests/group/${requestId}/addContestants`, true, JSON.stringify(model));
}

async function addContestant(id, requestId/*, fromRequestId*/) {
  let model = { requestId: requestId, userId: id }
  return await post(`/api/requests/group/${requestId}/addContestants`, true, JSON.stringify(model));
}

async function copyContestant(id, requestId) {
  let model = { requestId, contestants: [id] }
  return await post(`/api/requests/group/${requestId}/copyContestants`, true, JSON.stringify(model));
}

async function getSeriesContests(requestId) {
  return await get(`/api/requests/series/${requestId}`, true);
}

async function clearResults(requestId) {
  return await get(`/api/requests/results/${requestId}/clear`, true);
}

async function createRequestEx(model) {
  return await post(`/api/requests/create/external`, false, JSON.stringify(model));
}

async function getCoordinatorByRequestId(requestId) {
  return await get(`/api/requests/group/${requestId}/coordinators`, true);
}


async function getPaymentsForRequest(requestId) {
  return await get(`/api/requests/group/${requestId}/payments`, true);
}

async function getContestantsGroupedByRequest(requestId, page, pageSize, model) {
  let fio = model.fio ? `&fio=${model.fio}` : "";
  let email = model.emailPhone ? `&emailPhone=${model.emailPhone}` : "";
  let grade = model.grade ? `&class=${model.grade}` : "";

  return await get(`/api/requests/group/${requestId}/contestants?page=${page}&pageSize=${pageSize}&filterExisting=false${fio}${email}${grade}`, true);
}

async function createExternalPayment(requestId, model) {
  return await post(`/api/requests/group/${requestId}/createPayment`, true, model);
}

async function createTeacherRequest(variantId) {
  return await post(`/api/teacher/variants/${variantId}/createContest`, true);
}

async function createReportParams(requestId, model) {
  return await post(`/api/requests/group/${requestId}/reportTags`, true, model);
}

async function getReportData(requestId) {
  return await get(`/api/requests/group/${requestId}/reportData`, true);
}

async function setTeacherTestDuration(requestId, model) {
  return await post(`/api/requests/group/${requestId}/setTestDuration`, true, model);
}


export default requestService;
