import { get, post, put, remove } from "../_methods/download";

export const taskService = {
  createTask,
  updateTask,
  removeTask,
  getTasks,
  searchTasks,
  getAllTags,
  updateTags,
  getTask,
  getTasksByIds,
  getAltTasks,
  createAltTask,
  updateAltTask,
  removeAltTask,
  getTasksByIdsForTeacher,
  getTasksByTagIdsForTeacher,
  createTeacherTask,
  updateTeacherTask,
  removeTeacherTask,
  getTeacherTask,
  getTeacherTasks,
  searchTeacherTasks
};

async function createTask(model) {
  //return {data: {}, success: true };
  return await post(`/api/tasks/create`, true, model);
}

async function updateTask(id, model) {
  //return {data: {}, success: true };
  return await post(`/api/tasks/edit/${id}`, true, model);
}

async function getTasks(page, sizePerPage, filter, deleted = false) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  return await get(`/api/tasks?deleted=${deleted}&page=${page}&pageSize=${sizePerPage}&rc=${regionCode}`, true);
}

async function getTask(id) {
  return await get(`/api/tasks/${id}`, true);
}

async function searchTasks(model, page, pageSize, deleted = false) {
  return await post(`/api/tasks/search?deleted=${deleted}&page=${page}&pageSize=${pageSize}`, true, model);
}

async function getAllTags() {
  return await get(`/api/tags?deleted=false`, true);
}

async function updateTags(model) {
  return await post(`/api/tasks/updateTags`, true, model);
}

async function getTasksByIds(ids) {
  return await get(`/api/tasks?id=${ids}`, true);
}

async function getTasksByIdsForTeacher(ids, page, pageSize, keepOrder = false) {
  return await get(`/api/teacher/tasks?id=${ids}&page=${page}&pageSize=${pageSize}&my=${true}&keepOrder=${keepOrder}`, true);
}

async function getTasksByTagIdsForTeacher(ids, page, pageSize) {
  let myFilter = ids.includes(-1) ? `&my=${true}` : "";
  return await get(`/api/teacher/tasks?tagId=${ids}&page=${page}&pageSize=${pageSize}${myFilter}`, true);
}


async function getAltTasks(taskId) {
  return await get(`/api/tasks/${taskId}/altTasks?deleted=false`, true);
}

async function createAltTask(taskId, model) {
  return await post(`/api/tasks/${taskId}/altTasks`, true, model);
}

async function updateAltTask(taskId, altTaskId, model) {
  return await put(`/api/tasks/${taskId}/altTasks/${altTaskId}`, true, model);
}

async function removeAltTask(taskId, altTaskId) {
  return await remove(`/api/tasks/${taskId}/altTasks/${altTaskId}`, true);
}

async function removeTask(id) {
  return await remove(`/api/tasks/${id}`, true);
}

async function createTeacherTask(model) {
  //return {data: {}, success: true };
  return await post(`/api/teacher/tasks`, true, model);
}

async function updateTeacherTask(id, model) {
  //return {data: {}, success: true };
  return await put(`/api/teacher/tasks/${id}`, true, model);
}

async function getTeacherTasks(page, sizePerPage, filter, deleted = false) {
  return await get(`/api/teacher/tasks/me?deleted=${deleted}&page=${page}&pageSize=${sizePerPage}`, true);
}

async function getTeacherTask(id) {
  return await get(`/api/teacher/tasks/${id}`, true);
}

async function searchTeacherTasks(model, page, pageSize, deleted = false) {
  return await post(`/api/teacher/tasks/me/search?deleted=${deleted}&page=${page}&pageSize=${pageSize}`, true, model);
}

async function removeTeacherTask(id) {
  return await remove(`/api/teacher/tasks/${id}`, true);
}

export default taskService;
